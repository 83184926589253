import { ComponentType, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SerializedStyles } from '@emotion/react';

import MainModal from '@/components/MainModal/MainModal';
import { useAppDispatch, useAppSelector, useLoader, useOnboardingTask, useTracking } from '@/hooks';
import { MainLayout } from '@/layouts/MainLayout';
import { FlowModel } from '@/models/flow.model';
import { selectOnboardingPageTitle, setEUMember, updateStep } from '@/redux/features/onboardingSlice';
import CompanyRequests from '@/requests/company.requests';
import { Company, CompanyTrackingMode } from '@/types/company.type';
import { AllStepsInfo, StepTypes } from '@/types/steps.type';
import DeviceAndGeoDetector from '@/utils/device-detector.utils';

type OnboardingPageWrapperProps = {
  flow: FlowModel;
  css?: SerializedStyles;
  getStep: <T extends StepTypes>(stepKey: keyof T | undefined) => AllStepsInfo;
};

export const withOnboardingPageWrapper = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  {
    css,
    flow,
    getStep,
  }: OnboardingPageWrapperProps,
) => (
    function OnboardingPageWrapper(props: P) {
      const dispatch = useAppDispatch();
      const { company } = useAppSelector(state => state.auth);

      const { onAccountCreated } = useOnboardingTask(flow);
      const { trackOnboardingPage } = useTracking(flow);
      const { showLoader, hideLoader } = useLoader();
      const pageTitle = useSelector(selectOnboardingPageTitle);
      const didFirstLoad = useRef(false);

      const onInitPage = useCallback(async (company: Company) => {
        const { country } = await DeviceAndGeoDetector.initialize();
        const isUEMember = DeviceAndGeoDetector.isCountryInEU(country);
        const companyStep = getStep(company?.companySettings?.custom?.onboardingStep);
        dispatch(updateStep({ ...companyStep }));
        dispatch(setEUMember(isUEMember));

        const trackingMode: CompanyTrackingMode = isUEMember  ? 'interactive' : 'mixed';
        const tasksMode = trackingMode === 'mixed' ? 'preset' : 'off';
        flow.trackingMode = trackingMode;

        await CompanyRequests.putCompanySettings(company.id, {
          custom: { isUEMember },
          trackingMode,
          tasksMode,
        });

        await onAccountCreated(companyStep.stepName);
        trackOnboardingPage(companyStep.stepName);
      }, [dispatch, onAccountCreated, trackOnboardingPage]);

      useEffect(() => {
        if (company && !didFirstLoad.current) {
          didFirstLoad.current = true;

          hideLoader();
          onInitPage(company);
        }
      }, [company, onInitPage, hideLoader, showLoader]);

      return (
        <MainLayout title={ pageTitle } css={ css }>
          <MainModal flow={ flow }>
            <Component { ...props } />
          </MainModal>
        </MainLayout>
      );
    }
  );
