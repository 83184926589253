import { ComponentType, useEffect,useRef } from 'react';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/hooks';

export const enum Paths {
  Default = '/',
  OnboardingSSO = '/onboarding-sso',
}

export const withFlowGuard = <P extends Record<string, unknown>>(Component: ComponentType<P>) => (
  function FlowGuard(props: P) {
    const router = useRouter();
    const { company } = useAppSelector(state => state.auth);
    const didActivate = useRef(false);

    const checkIfIsOnboardingSSO = () => company?.splitTest?.some(st => st.name === 'google-auth' && st.value);

    useEffect(() => {
      if (!company) return;

      if (!didActivate.current) {
        if (router.pathname === Paths.OnboardingSSO && !checkIfIsOnboardingSSO()) {
          router.push(Paths.Default);
          return;
        }

        if (router.pathname === Paths.Default && checkIfIsOnboardingSSO()) {
          router.push(Paths.OnboardingSSO);
          return;
        }

        didActivate.current = true;
      }
    }, [router, company]);

    if (!company || !didActivate.current) {
      return null;
    }

    return <Component { ...props } />;
  }
);
