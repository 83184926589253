import { ComponentType, useCallback, useEffect, useRef, useState } from 'react';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { useRouter } from 'next/router';

import { useAppDispatch, useAppSelector, useLoader, useOnboardingTask, useTracking } from '@/hooks';
import { authThunk, setAdminLogin } from '@/redux/features/authSlice';
import { AuthorizationResponse, QueryParams } from '@/types/auth.type';

import tokenStorageService from '../../utils/token.util';

import { ErrorEventFeedback } from './ErrorEventFeedback';

export const withAuthGuard = <P extends Record<string, unknown>>(Component: ComponentType<P>) => (
  function AuthGuard(props: P) {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { showLoader, hideLoader } = useLoader();
    const { redirectToLogin } = useOnboardingTask();
    const { trackStepChangePage } = useTracking();
    const company = useAppSelector(state => state.auth.company);
    const [status, setStatus] = useState<'loading' | 'error' | 'completed'>('loading');
    const didFirstLoad = useRef(false);

    const trackAuth = useCallback(async () => {
      const categoryProp =  {};
      await trackStepChangePage('Auth', { ...categoryProp });
    }, [trackStepChangePage]);

    const authenticate = useCallback(async (token: string) => {
      const { payload } =
        (await dispatch(authThunk.authorization(token))) as PayloadAction<AxiosResponse<AuthorizationResponse>>;
      await dispatch(authThunk.freeEmailDomain());

      if (payload?.status === HttpStatusCode.Unauthorized) return redirectToLogin();
      if (payload?.status >= 400) return setStatus('error');

      if (company) await trackAuth();
      await router.push(`${ router.pathname }`);
      setStatus('completed');
    }, [dispatch, redirectToLogin, router, trackAuth]);

    const checkQueryToken = useCallback(async () => {
      const { token, adminLogin } = router.query as QueryParams;

      if (!token) return redirectToLogin();

      tokenStorageService.setToken(token);
      dispatch(setAdminLogin(adminLogin === '1'));
      await authenticate(token);
    }, [authenticate, dispatch, router, redirectToLogin]);

    /**
     * Check if there is token stored in storage or redirect otherwise
     */
    const checkStoredToken = useCallback(async () => {
      const storedToken = tokenStorageService.getToken();

      if (!storedToken) return redirectToLogin();

      await authenticate(storedToken);
    }, [authenticate, redirectToLogin]);

    useEffect(() => {
      if (didFirstLoad.current) return;

      showLoader();

      // Make sure we act only when router.query is hydrated
      if (Object.keys(router.query).length && router.query.token != null) {
        checkQueryToken();
        didFirstLoad.current = true;
      } else if (router.isReady && !router.query?.token) {
        checkStoredToken();
        didFirstLoad.current = true;
      }
    }, [router, dispatch, checkQueryToken, checkStoredToken, showLoader]);

    useEffect(() => {
      if (status === 'error') hideLoader();
    }, [status, hideLoader]);

    if (status === 'loading') return null;
    if (status === 'error') return <ErrorEventFeedback />;

    return <Component { ...props } />;
  }
);
