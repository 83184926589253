import type React from 'react';
import type { SvgIconProps } from '@mui/material/SvgIcon';

import { customIcons } from './custom-icons';
import { materialIcons } from './material-icons';

export type RegisteredIcon = keyof typeof registeredIcons;

type IconProps = {
  iconName: RegisteredIcon;
} & SvgIconProps


const registeredIcons = {
  ...customIcons(),
  ...materialIcons(),
};

export const IconComponent: React.FC<IconProps> = ({ iconName, ...svgProps }) => {
  const RegisteredIcon = registeredIcons[iconName];

  return <RegisteredIcon { ...svgProps } />;
};
