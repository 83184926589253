import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

export const materialIcons = () => ({
  'error': ErrorIcon,
  'error-outlined': ErrorOutlinedIcon,
  'info': InfoIcon,
  'info-outlined': InfoOutlinedIcon,
  'warning': WarningIcon,
  'warning-outlined': WarningOutlinedIcon,
});
