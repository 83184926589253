import { FC } from 'react';
import { css } from '@emotion/react';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';

import { FlexBox } from '@/shared/FlexBox';
import { Icon, RegisteredIcon } from '@/shared/Icon';
import { RenderIf } from '@/shared/RenderIf';


type EventFeedbackProps = {
  icon: RegisteredIcon;
  title: string;
  desc?: string;
  color?: 'primary' | 'secondary' | 'neutral';
  action?: {
    label: string;
    onClick: () => void;
  }
}

export const EventFeedbackComponent: FC<EventFeedbackProps> = ({ icon, title, desc, color = 'primary', action }) => (
  <FlexBox flexDirection="column" alignItems="center" rowGap={ 3 } flex="1 auto">
    <IconWrapper data-color={ color }>
      <Icon iconName={ icon } sx={{ fontSize: 48, color: `${ color }.main` }} />
    </IconWrapper>

    <FlexBox rowGap={ 1 } flexDirection="column">
      <MuiTypography variant="h4" textAlign="center">{ title }</MuiTypography>
      <MuiTypography variant="body2" textAlign="center">{ desc }</MuiTypography>
    </FlexBox>

    <RenderIf condition={ !!action }>
      <MuiButton variant="contained" onClick={ action?.onClick }>
        { action?.label }
      </MuiButton>
    </RenderIf>
  </FlexBox>
);

const IconWrapper = styled('div')(({ theme }) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;

  &[data-color="primary"] {
    --bg-color: ${ theme.palette.primary.main };
  }

  &[data-color="secondary"] {
    --bg-color: ${ theme.palette.secondary.main };
  }

  &[data-color="neutral"] {
    --bg-color: ${ theme.palette.neutral.main };
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--bg-color);
    opacity: 0.1;
  }
`);
