import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import Head from 'next/head';
import NextImage from 'next/image';

import styles from './MainLayout.module.scss';

type MainLayoutProps = PropsWithChildren<{
  title: string
} & HTMLAttributes<HTMLDivElement>>

export const MainLayout: FC<MainLayoutProps> = ({ title, children, ...restProps }) => (
  <main className={ styles.container } { ...restProps }>
    <Head>
      <title>{ title }</title>
    </Head>

    <NextImage
      src="/assets/onboarding/dashboardbg.png"
      alt=""
      width={ 1366 } height={ 634 }
      className={ styles.bgImage }
    />
    <div className={ styles.main }>
      { children }
    </div>
  </main>
);
