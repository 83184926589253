import { css, Theme } from '@emotion/react';


export const styles = {
  Root: (theme: Theme) => css`
    --gutter: var(--main-modal-gutter, 24px);

    display: flex;
    flex-direction: column;

    margin: auto;
    padding: 24px var(--gutter) 0;
    max-width: var(--main-modal-width, 732px);
    width: calc(100% - 32px);
    height: auto;
    max-height: calc(100vh - 48px);

    border-radius: 8px;
    background: white;

    ${ theme.breakpoints.down('xxl') } {
      max-width: var(--main-modal-width, 658px);
    }

    ${ theme.breakpoints.down('sm') } {
      --gutter: 16px;

      width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    @media (max-height: 650px) {
      max-height: 100%;
    }
  `,
  Header: css`
    flex-shrink: 0;
  `,
  Content: css`
    display: flex;
    flex-direction: column;

    margin: 0 calc(-1 * var(--gutter)) var(--gutter);
    padding: 0 var(--gutter);
    height: 100%;
    overflow: auto;
  `,
};
