import type { FC } from 'react';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

import BuildingsSvg from '/public/assets/icons/buildings.svg';
import CallCenterSvg from '/public/assets/icons/call-center.svg';
import CashSvg from '/public/assets/icons/cash.svg';
import DollarSignSvg from '/public/assets/icons/dollar-sign.svg';
import ListCheckSvg from '/public/assets/icons/list-check.svg';
import OthersSvg from '/public/assets/icons/others.svg';
import PersonAttritionSvg from '/public/assets/icons/person-attrition.svg';
import PersonExchangeSvg from '/public/assets/icons/person-exchange.svg';
import PersonProductivitySvg from '/public/assets/icons/person-productivity.svg';
import ProgrammingSvg from '/public/assets/icons/programming.svg';
import TeamSvg from '/public/assets/icons/team.svg';
import TimeAnalyticsSvg from '/public/assets/icons/time-analytics.svg';

export const BuildingsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ BuildingsSvg } inheritViewBox { ...props } />
);
export const CallCenterIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ CallCenterSvg } inheritViewBox { ...props } />
);
export const CashIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ CashSvg } inheritViewBox { ...props } />
);
export const DollarSignIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ DollarSignSvg } inheritViewBox { ...props } />
);
export const ListCheckIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ ListCheckSvg } inheritViewBox { ...props } />
);
export const OthersIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ OthersSvg } inheritViewBox { ...props } />
);
export const PersonAttritionIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ PersonAttritionSvg } inheritViewBox { ...props } />
);
export const PersonExchangeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ PersonExchangeSvg } inheritViewBox { ...props } />
);
export const PersonProductivityIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ PersonProductivitySvg } inheritViewBox { ...props } />
);
export const ProgrammingIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ ProgrammingSvg } inheritViewBox { ...props } />
);
export const TeamIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ TeamSvg } inheritViewBox { ...props } />
);
export const TimeAnalyticsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon component={ TimeAnalyticsSvg } inheritViewBox { ...props } />
);

export const customIcons = () => ({
  'buildings': BuildingsIcon,
  'call-center': CallCenterIcon,
  'cash': CashIcon,
  'dollar-sign': DollarSignIcon,
  'list-check': ListCheckIcon,
  'others': OthersIcon,
  'person-attrition': PersonAttritionIcon,
  'person-exchange': PersonExchangeIcon,
  'person-productivity': PersonProductivityIcon,
  'programming': ProgrammingIcon,
  'team': TeamIcon,
  'time-analytics': TimeAnalyticsIcon,
});
