import React, { PropsWithChildren } from 'react';

import { FlowModel } from '@/models/flow.model';

import { OnboardingProgress } from '../OnboardingProgress';

import { styles } from './MainModal.styles';

type MainModalProps = PropsWithChildren<{
  flow: FlowModel;
}>;

const MainModal: React.FC<MainModalProps> = ({ children, flow }) => (
  <div css={ styles.Root }>
    <OnboardingProgress css={ styles.Header } flow={ flow } />
    <div css={ styles.Content }>
      { children }
    </div>
  </div>
);

export default MainModal;
