import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';

import { EventFeedback } from '@/shared/EventFeedback';
import { FlexBox } from '@/shared/FlexBox';


const Wrapper = styled(FlexBox)`
  margin: 0 auto;
  width: 600px;
  max-width: calc(100% - 32px);
  height: 100vh;
`;

export const ErrorEventFeedback = () => {
  const { t } = useTranslation();

  function handleReload() {
    window.location.reload();
  }

  return (
    <Wrapper alignItems="center">
      <EventFeedback
        icon="error-outlined"
        color="secondary"
        title={ t('common:errors.auth.title') }
        desc={ t('common:errors.auth.desc') }
        action={{
          label: t('common:reload'),
          onClick: handleReload,
        }}
      />
    </Wrapper>
  );
};
