import { ComponentType, useRef } from 'react';

import { useAppSelector, useOnboardingTask, useTraits } from '@/hooks';

export const withOnboardingGuard = <P extends Record<string, unknown>>(Component: ComponentType<P>) => (
  function OnboardingGuard(props: P) {
    const { company } = useAppSelector(state => state.auth);
    const { getCompanyTraits } = useTraits();
    const { redirectToOfferOrDashboard } = useOnboardingTask();
    const didActivate = useRef(false);

    const checkIfCompletedOnboarding = () => {
      const companyTraits = getCompanyTraits();
      const isCompletedOnSettings = company?.companySettings?.custom?.completedOnBoarding === 'yes'
        || company?.companySettings?.custom?.completedOnBoarding;
      const isCompletedOnTraits = companyTraits?.onboarding_step === 'Completed Onboarding';

      return isCompletedOnSettings || isCompletedOnTraits;
    };


    if (didActivate.current) {
      return <Component { ...props } />;
    }

    if (!company) return null;

    if (checkIfCompletedOnboarding()) {
      redirectToOfferOrDashboard();
      return null;
    }

    didActivate.current = true;
    return <Component { ...props } />;
  }
);
